import { combineReducers } from 'redux'
import { productsReducer } from '../productosDuck'
import { tutorialsReducer } from '../tutorialesDuck'
import { filtrosReducer } from '../filtrosDuck'
import newsletterReducer from "../newsletterDuck";

export default combineReducers({
    productos: productsReducer,
    tutoriales: tutorialsReducer,
    filtros: filtrosReducer,
    newsletter: newsletterReducer,
})