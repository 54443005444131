import React, { useEffect, useState } from "react";
import "./NewsletterPopup.scss";

import { useDispatch, useSelector } from "react-redux";
import { newsletterClosePopup, submitNewsletter } from "../../../redux/newsletterDuck";

const NewsletterPopup = () => { 
  const [formData, setFormData] = useState({ nombre_completo: "", email: "" });
  

  const dispatch = useDispatch();
  const { loading, successMessage, errorMessage } = useSelector(
    (state) => state.newsletter
  );

  //Abre 1 vez
  useEffect(() => {
    localStorage.setItem('showPopupNewsletter', true);
  }, []);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(submitNewsletter(formData)); 
  };

 
  return (
    <div className="newsletter-popup">
      <div className="popup-content">
        <button className="close-btn" onClick={() => dispatch(newsletterClosePopup())}>×</button>
        <h2>Suscribite a nuestro newsletter</h2>
        {!successMessage && !errorMessage && <span>Registrate para recibir novedades, tips y descuentos.</span>}
        
        {loading && <p>Enviando...</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {!loading && !successMessage && (
          <form onSubmit={handleSubmit}>
            <div> 
              <input
                type="text"
                name="nombre_completo"
                placeholder="Nombre Completo"
                value={formData.nombre_completo}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                placeholder="Email"
                onChange={handleInputChange}
                required
              />
            </div>
            <button type="submit">CONFIRMAR</button>
          </form>
        )}
      </div>
    </div>
  );
};
export default NewsletterPopup;
