import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { Chat } from '../@ui'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewsletterPopup from '../@ui/NewsletterPopup';
import { newsletterOpenPopup, newsletterClosePopup } from "../../redux/newsletterDuck";

const CHAT_ENABLED = false;

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const newsletter_open_global_state = useSelector(
      (state) => state.newsletter.isPopupOpen
    );
  
    useEffect(() => {
      const showPopupOnce = localStorage.getItem("showPopupNewsletter") === null;
      if (showPopupOnce) { 
        dispatch(newsletterOpenPopup());
      }
    }, [dispatch]);
  
    const closePopup = () => {
      localStorage.setItem("showPopupNewsletter", "true"); // Marcar como mostrado
      dispatch(newsletterOpenPopup());
    };

    return (
        <>
            <Header />
            {newsletter_open_global_state && <NewsletterPopup onClose={closePopup} />}
            {CHAT_ENABLED && <Chat />}
            {children}
            <Footer />
        </>
    );
}

export default Layout;