import React, { useEffect } from "react";
import Banner from "./Banner";
import Categorias from "./Categorias";
import Tutoriales from "./Tutoriales";
import Productos from "./Productos";
import { scrollToTop } from "../../utils";

const Index = () => {
 
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Banner />
      <Categorias />
      <Tutoriales />
      <Productos />
    </>
  );
};

export default Index;
