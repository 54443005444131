import axiosClient from "./config/axiosClient";

const NEWSLETTER_REQUEST = "newsletter/REQUEST";
const NEWSLETTER_SUCCESS = "newsletter/SUCCESS";
const NEWSLETTER_FAILURE = "newsletter/FAILURE";
const NEWSLETTER_OPEN_POPUP = "newsletter/OPEN_POPUP";
const NEWSLETTER_CLOSE_POPUP = "newsletter/CLOSE_POPUP";

export const newsletterClosePopup = () => ({
  type: NEWSLETTER_CLOSE_POPUP,
})

export const newsletterOpenPopup = () => ({
  type: NEWSLETTER_OPEN_POPUP,
})

export const newsletterRequest = () => ({
  type: NEWSLETTER_REQUEST,
});

export const newsletterSuccess = (message) => ({
  type: NEWSLETTER_SUCCESS,
  payload: message,
});

export const newsletterFailure = (error) => ({
  type: NEWSLETTER_FAILURE,
  payload: error,
});

const initialState = {
  loading: false,
  isPopupOpen: false,
  successMessage: null,
  errorMessage: null,
};

export default function newsletterReducer(state = initialState, action) {
  switch (action.type) {
    case NEWSLETTER_REQUEST:
      return {
        ...state,
        loading: true,
        successMessage: null,
        errorMessage: null,
      };

    case NEWSLETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        errorMessage: null,
      };

    case NEWSLETTER_FAILURE:
      return {
        ...state,
        loading: false,
        successMessage: null,
        errorMessage: action.payload,
      };

    case NEWSLETTER_OPEN_POPUP:
      return {
        ...state,
        isPopupOpen: true,
      };

    case NEWSLETTER_CLOSE_POPUP:
      return {
        ...state,
        isPopupOpen: false,
      }
      
    default:
      return state;
  }
}

// logica de la peticion thunx
export const submitNewsletter = (formData) => async (dispatch) => {
  dispatch(newsletterRequest());

  try {
    const checkEmail = await axiosClient.get(
      `/items/newsletter?filter[email]=${formData.email}`
    );

    if (checkEmail.data?.data.length > 0) {
      dispatch(newsletterFailure("El correo ya esta suscripto."));
      return; 
    }

    const response = await axiosClient.post("/items/newsletter", formData, {
      headers: { "Content-Type": "application/json" },
    });

    const data = response.data?.data;

    if (data?.id) {
      dispatch(newsletterSuccess("¡Gracias por suscribirte!"));
    } else {
      dispatch(
        newsletterFailure("Hubo un error. Por favor, intenta de nuevo.")
      );
    }
  } catch (error) {
    dispatch(
      newsletterFailure(
        error?.response?.data?.message || "Error al enviar el formulario."
      )
    );
  }
};
